.css-1u9des2-indicatorSeparator {
  display: none;
}

.css-t3ipsp-control {
  min-height: 44px !important;
  border-color: $__metallic-silver !important;
  box-shadow: 0 0 0 1px #e3e3e3 !important;

  &:hover {
    border-color: $__metallic-silver !important;
  }
}

.css-13cymwt-control {
  &:hover {
    border-color: $__metallic-silver !important;
  }

  border-color: $__metallic-silver !important;
  box-shadow: none;
  font-size: 15px;
  height: 44px;
  line-height: 2;
}

.css-1nmdiq5-menu {
  ul {
    li {
      background: yellow !important;
    }
  }
}

// Antd Design

.data-table-card {
  .ant-table-content {
    .ant-table-tbody {
      tr {
        &:nth-of-type(2n + 1) {
          background-color: transparent;
        }
      }

      td {
        padding: 0.5rem;
      }
    }
  }
}

.ant-tooltip {
  display: none;
}

.ant-table-wrapper {
  .ant-table-thead {
    th.ant-table-column-has-sorters {
      &:hover {
        background: $__whitecolor;
      }
    }

    th.ant-table-column-sort {
      background: $__whitecolor;
    }

    >tr {
      >th {
        background: $__whitecolor;

        &:not(:last-child) {
          &:not(.ant-table-selection-column) {
            &:not(.ant-table-row-expand-icon-cell) {
              &:not([colspan]) {
                &::before {
                  display: none;
                }
              }
            }
          }
        }
      }

      >td {
        background: $__whitecolor;

        &:not(:last-child) {
          &:not(.ant-table-selection-column) {
            &:not(.ant-table-row-expand-icon-cell) {
              &:not([colspan]) {
                &::before {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }

  td.ant-table-column-sort {
    background: transparent;
  }

  .ant-table-column-sorter-up.active {
    color: $__basecolor;
  }

  .ant-table-column-sorter-down.active {
    color: $__basecolor;
  }

  .ant-table-tbody {
    .ant-table-row {
      >.ant-table-cell-row-hover {
        background: transparent;
      }
    }
  }

  .ant-table {
    font-family: $__body__font__family;
    font-size: $__body__font__size;
    color: $__secondarycolor;
  }
}

.ant-table-content {
  .ant-table-tbody {
    tr {
      &:nth-of-type(2n + 1) {
        background-color: $__dark__light;
      }
    }
  }

  table {
    th {
      white-space: nowrap;
      border-top: 1px solid #e2e5e8;
      padding: 1rem 0.75rem;
    }

    td {
      border-top: 1px solid #e2e5e8;
      white-space: nowrap;
      vertical-align: middle;
      padding: 0.5rem;
      color: $__gray;
    }

    .ant-table-tbody {
      .ant-table-rowant-table-row {
        color: $__gray;
      }
    }
  }
}

.ant-pagination {
  .ant-pagination-item-active {
    color: $__whitecolor !important;
    background-color: $__primarycolor;
    border-color: $__primarycolor;

    &:hover {
      border-color: $__primarycolor;
    }
  }

  .ant-pagination-item {
    color: $__primarycolor;
  }
}

.ant-table td h2 {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

.ant-table td h2 a {
  color: #333333;
}

.ant-table-wrapper .ant-table-tbody>tr>td {
  padding: 10px 20px;
}

.dataTables_length label {
  align-items: baseline;
}

.form-control:focus {
  border-color: $__metallic-silver;
}

.react-datepicker__day--selected:hover {
  background-color: $__purpletext;
}

.react-datepicker__day--selected {
  background-color: $__purpletext;
}

.css-1jqq78o-placeholder {
  color: $__dark__blue !important;
}

.range-slider__tooltip {
  display: none;
}

.ant-table-content {
  .ant-table-tbody {
    tr {
      &:nth-of-type(2n + 2) {
        background: $__whitecolor;
      }
    }
  }
}

.ant-table-content .ant-table-tbody td a {
  color: #333333;
}

.ant-table-wrapper .ant-table-column-title {
  z-index: unset !important;
}

#close-task-panel.btn-white {
  background-color: unset;
  border: 0px solid #cccccc;
}

.filter-row .css-13cymwt-control {
  height: 50px;
}

.filter-row .css-t3ipsp-control {
  height: 50px;
}

.filter-row .css-13cymwt-control .css-1jqq78o-placeholder {
  padding-top: 14px;
}

.filter-row .css-t3ipsp-control .css-1jqq78o-placeholder {
  padding-top: 14px;
}

.filter-row .css-t3ipsp-control .css-1dimb5e-singleValue {
  padding-top: 14px;
}

.filter-row .css-13cymwt-control .css-1dimb5e-singleValue {
  padding-top: 14px;
}

//new changes
.react-datepicker-wrapper {
  width: 100% !important;
}

.rsw-ce {
  flex: 1 0 auto;
  outline: none;
  padding: 0.5rem;
  height: 150px;
}

.ant-picker-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: $color_1;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1150;
}

[data-layout-mode="dark"] .css-13cymwt-control {
  background-color: #16191c !important;
  color: #bbc4cc !important;
  border: 1px solid #2e3840 !important;
}

[data-layout-mode="dark"] .css-t3ipsp-control:hover {
  background-color: #16191c !important;
  color: #bbc4cc !important;
  border: 1px solid #2e3840 !important;
}

[data-layout-mode="dark"] .css-t3ipsp-control {
  background-color: #16191c !important;
  color: #bbc4cc !important;
  border: 1px solid #2e3840 !important;
}

[data-layout-mode="dark"] .form-focus .form-control:focus {
  background-color: #16191c !important;
  color: #bbc4cc !important;
  border: 1px solid !important;
  border-color: $__metallic-silver !important;
}

[data-layout-mode="dark"] .header .user-menu.nav>li.flag-nav .dropdown-menu {
  background-color: #29363c !important;
  color: #bbc4cc !important;
}

[data-layout-mode="dark"] .dropdown-menu {
  background-color: #29363c !important;
  color: #bbc4cc !important;
}

[data-layout-mode="dark"] .dropdown-item:hover {
  background-color: #29363c !important;
  color: #bbc4cc !important;
}

[data-layout-mode="dark"] .topnav-dropdown-header .notification-title {
  color: #bbc4cc !important;
}

[data-layout-mode="dark"] .dropdown-item {
  color: #bbc4cc !important;
}

[data-layout-mode="dark"] .notifications .notification-list li .list-item .list-body .message-content {
  color: #bbc4cc !important;
}

[data-layout-mode="dark"] .notifications .notification-list li .list-item .list-body .message-author {
  color: #bbc4cc !important;
}

[data-layout-mode="dark"] .notifications .notification-list li .noti-title {
  color: #bbc4cc !important;
}

[data-layout-mode="dark"] .header .user-menu.nav>li.flag-nav .dropdown-menu .dropdown-item {
  background-color: #29363c !important;
  color: #bbc4cc !important;
}

[data-layout-mode="dark"] .header .user-menu.nav>li.flag-nav .dropdown-menu .dropdown-item:hover {
  background-color: #29363c !important;
  color: #bbc4cc !important;
}

[data-layout-mode="dark"] .topnav-dropdown-footer {
  background-color: #29363c !important;
  color: #bbc4cc !important;
}

[data-layout-mode="dark"] .topnav-dropdown-footer a {
  color: #bbc4cc !important;
}

[data-layout-mode="dark"] .anticon svg {
  color: #bbc4cc !important;
}

[data-layout-mode="dark"] .ant-table-wrapper .ant-table-thead>tr>td {
  background: #16191c;
}

[data-layout-mode="dark"] .css-1nmdiq5-menu * {
  background-color: #16191c !important;
  color: #bbc4cc !important;
}

[data-layout-mode="dark"] .ant-table {
  border: 1px solid #2e3840;
  background: #16191c;
}

[data-layout-mode="dark"] .react-datepicker * {
  background-color: #16191c !important;
  color: #bbc4cc !important;
}

[data-layout-mode="dark"] th.ant-table-cell.ant-table-column-has-sorters {
  background: #16191c;
  color: #bbc4cc !important;
  border: 1px solid #2e3840 !important;
}

[data-layout-mode="dark"] th.ant-table-cell {
  background: #16191c !important;
  color: #bbc4cc !important;
  border: 1px solid #2e3840 !important;
}

[data-layout-mode="dark"] th.ant-table-cell.ant-table-column-has-sorters:hover {
  background: #16191c;
  color: #bbc4cc !important;
  border: 1px solid #2e3840 !important;
}

[data-layout-mode="dark"] td.ant-table-cell {
  background: #16191c;
  color: #bbc4cc !important;
  border: 1px solid #2e3840 !important;
}

[data-layout-mode="dark"] .ant-table-content .ant-table-tbody tr:nth-of-type(2n + 1) {
  background: transparent !important;
}

[data-layout-mode="dark"] .ant-table-content .ant-table-tbody td a {
  // background: #16191c;
  color: #bbc4cc !important;
  // border: 1px solid #2e3840 !important;
}

// [data-layout-mode=dark] td.ant-table-cell:hover{
//   background: #16191c;
//   color: #bbc4cc !important;
//   border: 1px solid #2e3840 !important;
// }

[data-layout-mode="dark"] .ant-table-content .ant-table-tbody tr:nth-of-type(2n + 2) {
  background: transparent !important;
}

// [data-layout-mode=dark] .ant-table-wrapper .ant-table-tbody > tr > td:hover{
//   background: #16191c;
//   color: #bbc4cc !important;
//   border: 1px solid #2e3840 !important;
// }
[data-layout-mode="dark"] .ant-table-wrapper .ant-table-tbody>tr>td {
  background: #16191c;
  color: #bbc4cc !important;
  border: 1px solid #2e3840 !important;
}

// [data-layout-mode=dark] .ant-table-cell .ant-table-cell-row:hover{
//   background: #16191c;
//   color: #bbc4cc !important;
//   border: 1px solid #2e3840 !important;
// }
[data-layout-mode="dark"] .modal-content {
  background: #16191c;
  color: #bbc4cc !important;
  border: 1px solid #2e3840 !important;
}

[data-layout-mode="dark"] .fc .fc-daygrid-day-number {
  color: #bbc4cc !important;
}

[data-layout-mode="dark"] .css-1dimb5e-singleValue {
  color: #bbc4cc !important;
}

[data-layout-mode="dark"] .css-1jqq78o-placeholder {
  color: #bbc4cc !important;
}

[data-layout-mode="dark"] .picker-input>input {
  color: #bbc4cc !important;
}

.anticon.anticon-caret-up.ant-table-column-sorter-up {
  right: 7px;
  position: relative;
  bottom: 4px;
}

.anticon.anticon-caret-up.ant-table-column-sorter-down {
  position: relative;
}

.ant-table-column-sorter-up {
  &::after {
    content: "↑";
  }
}

.ant-table-column-sorter-down {
  &::after {
    content: "↓";
  }
}

.anticon {
  svg {
    // FIXME: effecting in global scope
    display: none;
  }
}

.ant-table-wrapper {
  .ant-table-column-sorter {
    position: relative;
    top: 5px;
    left: 10px;
  }
}

.text-secondary input {
  margin-left: 2px !important;
}

.mini-sidebar {
  .two-col-bar {
    .sidebar {
      width: 60px;

      .sidebar-right {
        display: none;
      }
    }
  }

  &.expand-menu {
    .sidebar {
      width: 230px;

      .sidebar-right {
        display: block;
      }
    }
  }
}

.mini-sidebar {
  .sidebar {
    .sidebar-menu ul {
      display: none;
    }
  }

  &.expand-menu {
    .sidebar {
      .sidebar-menu ul {
        display: block;
      }
    }
  }
}

[data-layout="horizontal"] .hidden-links li a:hover {
  color: #343a40 !important;
}

[data-sidebar-size="md"] .mini-sidebar .sidebar {
  width: 200px;
}

.flag-nav {
  min-width: 90px !important;
}

.css-1nmdiq5-menu {
  z-index: 99999999;
}

.tblbox {
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #fefefe;
  width: 75%;
  margin-bottom: 20px;
  margin-left: 12%;
  margin-right: 20%;
  display: block;
  background-color: #fefefe;
  box-shadow: 0 3px 5px -3px #000;
}

.tblboxheader {
  margin-top: 5%;
  margin-left: 8%;
  padding: 10px;
  // border: 1px solid #000;
  width: 75%;
  display: block;
}

.firstinput {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
}

.firstlabel {
  width: 100px;
  height: 20px;
  border: 1px solid #000;
}

.dateoutline {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.firtrow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.textarea1 {
  padding-top: 10px;
  padding-bottom: 25px;
  width: 100%;
  border-radius: 5px;
  padding: 15px;
  font-size: 13px;
  display: block;
  resize: none;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #7d7f80;
  overflow: hidden;
  border-color: #cccccc;
}

.textarea1:focus {
  outline: none !important;
  border: 2px solid #41b909;
  // box-shadow: 0 0 10px #343a40;
}

.firstcol {
  display: flex;
  flex-direction: row;
}

.firstfield {
  width: 30%;
}

.inputfield {
  display: flex;
  flex-direction: row;
}

.tblbox1 {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding: 10px;
  // border: 1px solid #a9a5a5;
  border: 0.5px solid #fefefe;
  box-shadow: 0 1px 1px -3px #656565;
  background-color: #fefefe;
  border-bottom: none;
  width: 75%;
  margin: 0% 12%;
  display: block;
}

.tblbox3 {
  padding: 10px;
  // border: 1px solid #a9a5a5;
  border: 1px solid #fefefe;
  box-shadow: 0 3px 5px -3px #000;
  background-color: #fefefe;
  border-bottom: none;
  width: 75%;
  margin: 0% 12%;
  display: block;
}

.tblbox2 {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 10px;
  // border: 1px solid #a9a5a5;
  border: 1px solid #fefefe;
  box-shadow: 0 3px 5px -3px #000;
  background-color: #fefefe;
  width: 75%;
  margin: 0% 12%;
  display: block;
}

.lblVal1 {
  // padding: 10px;
  font-weight: 800;
  color: #7d7a7a;
}

.lblVal {
  padding: 0px 8px;
  color: #7d7a7a;
}

.fit-content {
  width: auto;
}

.textAreaField {
  border: none;
}

.link {
  color: #fefefe;
  padding: 5px;
  background-color: $__primarycolor;
  border-radius: 5px;
}

#add_addmission {
  width: 1000px !important;
}

.datetimepicker {
  background-color: transparent;
  color: #7d7a7a;
}

// .printable-content {
//   width: 21cm; /* A4 width */
//   height: 29.7cm; /* A4 height */
//   margin: auto; /* Center align horizontally */
//   padding: 1cm; /* Add padding */
//   box-sizing: border-box; /* Include padding in width calculation */
//   border: 1px solid #ccc; /* Add border for visualization */
// }

.btnmargin {
  margin-right: 13%;
}

.dislink {
  color: #fefefe;
  padding: 5px;
  background-color: rgb(10, 181, 47);
  border-radius: 5px;
}

.link {
  border: 1px solid transparent;
}

.link:hover {
  color: blue;
  /* Change the text color on hover */
  background-color: white;
  /* Change the background color on hover */
  border-color: blue;
}

/* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}*/

.loginbody {
  /*background-color: #c9d6ff;*/
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}

.logincontainer {
  background-color: $__primarycolor;
  border-radius: 30px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
  position: relative;
  overflow: hidden;
  width: 768px;
  max-width: 100%;
  min-height: 480px;
}

.loginlogoImageConatiner {
  width: 35%;
  position: absolute;
  top: 36%;
  right: 56%;
}

.logincontainer button {
  background-color: $__primarycolor;
  color: #fff;
  font-size: 12px;
  padding: 10px 45px;
  border: 1px solid transparent;
  border-radius: 8px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-top: 10px;
  cursor: pointer;
}

.logincontainer button.hidden {
  /*background-color: transparent;*/
  border-color: $__primarycolor;
}

.logintoggle-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: all 0.6s ease-in-out;
  border-radius: 150px 0 0 100px;
  z-index: 1000;
}

.logincontainer.active .logintoggle-container {
  transform: translateX(-100%);
  border-radius: 0 150px 100px 0;
}

.logintoggle {
  background-color: #f9f7ff;
  height: 100%;
  background: linear-gradient(to right, #dfe0e4);
  color: #fff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: all 0.6s ease-in-out;
}

.logincontainer.active .logintoggle {
  transform: translateX(50%);
}

.logintoggle-panel {
  position: absolute;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 30px;
  text-align: center;
  top: 0;
  transform: translateX(0);
  transition: all 0.6s ease-in-out;
}

.logintoggle-left {
  transform: translateX(-200%);
}

.logincontainer.active .logintoggle-left {
  transform: translateX(0);
}

.logintoggle-right {
  right: 0;
  transform: translateX(0);
}

.logincontainer.active .logintoggle-right {
  transform: translateX(200%);
}

.header {
  background: $__primarycolor;
  border-bottom: 1px solid transparent;
  height: 60px;
  z-index: 1044;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
}

.bolder {
  font-weight: bold !important;
  color: rgb(139, 137, 137);
}

.bolder ::after {
  content: " / ";
}

.bolder :nth-child(2)::after,
.bolder :nth-child(3)::after {
  content: none;
}

.textval {
  font-size: 11px !important;
  color: rgb(139, 137, 137);
}

.Button_background_color {
  background-color: $__primarycolor;
}

.btnColor {
  background-color: rgb(240, 112, 112);
}

.red {
  color: red;
}

.green {
  color: green;
}

// ol,
// ul,
// dl {
//   margin-top: 0;
//   margin-bottom: 0rem !important;
// }

.center {
  display: flex;
  justify-content: center;
  font-size: 18px;
  color: red;
  font-weight: 800;
  text-align: center;
  vertical-align: middle;
}

.profile-name {
  position: relative;
}

.role-name {
  display: block;
  font-size: 0.8em;
  margin-top: 3px;
  // color: rgb(190, 202, 190);
  color: white;
  position: absolute;
  top: 13px;
  left: 50px;
}

.IPSummary {
  justify-content: space-between;


}

.hyperlink {
  text-decoration: underline;
  color: blue !important;
  margin-left: 20px;
}


.personalDetails
{
  margin-left: 20px;
}

.drugCard {
  margin: 6% 20%;
  width: 100%;
}